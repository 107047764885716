import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16, _templateObject17;

import { media, styled } from "@styles";
export var CheckoutContainer = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  flex: 1;\n  padding: 50px 0;\n  min-height: 80vh;\n  ", "\n"])), media.smallScreen(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    padding: 25px 0;\n  "]))));
export var DefaultLoader = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(255, 255, 255, 0.7);\n  z-index: 10;\n"])));
export var Wrapper = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  margin: 45px 0;\n  display: grid;\n\n  grid-template-columns: 8fr 4fr;\n  grid-template-rows: 85px auto auto;\n  grid-column-gap: 30px;\n  grid-template-areas:\n    \"navigation cartSummary\"\n    \"checkout cartSummary\"\n    \"paymentGateways cartSummary\"\n    \"button cartSummary\";\n  width: 75%;\n  max-width: 1200px;\n  margin: auto;\n\n  ", "\n\n  @media (max-width: 1024px), (max-width: 992px) and (orientation: landscape) {\n    width: 100%;\n    padding: 20px;\n  }\n\n  ", "\n"])), media.smallScreen(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n    width: auto;\n    display: flex;\n    flex-direction: column;\n  "]))), media.mediumScreen(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n    grid-template-columns: 1fr;\n    grid-template-areas:\n      \"navigation\"\n      \"checkout\"\n      \"paymentGateways\"\n      \"button\";\n  "]))));
export var Navigation = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  grid-area: navigation;\n  max-width: 550px;\n  ", "\n"])), media.smallScreen(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n    margin-bottom: 55px;\n  "]))));
export var Checkout = styled.div(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  max-width: 550px;\n  grid-area: checkout;\n"])));
export var PaymentGateways = styled.div(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  ", "\n  grid-area: paymentGateways;\n"])), function (props) {
  return props.hide && "display: none;";
});
export var CartSummary = styled.div(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  grid-area: cartSummary;\n"])));
export var Button = styled.div(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n  grid-area: button;\n  margin-top: 50px;\n  ", "\n"])), media.smallScreen(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n    margin-bottom: 40px;\n  "]))));
export var RPT = styled.div(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n"])));
export var RP = styled.div(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\n  color: black;\n  font-size: 0.875rem;\n  text-align: left;\n  margin: 20px 0 0 0;\n  cursor: pointer;\n  &:hover {\n    color: #666666;\n  }\n"])));
export var Terms = styled.div(_templateObject16 || (_templateObject16 = _taggedTemplateLiteral(["\n  color: black;\n  font-size: 0.875rem;\n  text-align: left;\n  margin: 7.5px 0 0 0;\n  cursor: pointer;\n  &:hover {\n    color: #666666;\n  }\n"])));
export var ReturnPolicyContent = styled.div(_templateObject17 || (_templateObject17 = _taggedTemplateLiteral(["\n  padding: 20px;\n  color: $black;\n  font-size: 14px;\n  text-align: center;\n"])));