import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;

import { styled } from "@styles";
import { css } from "styled-components";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  background-color: #f4f5f6;\n  border: 1px solid #d3d3d3;\n  border-radius: 5px;\n  overflow: auto;\n  height: 100%;\n  padding: 0;\n  transition: all 0.3s, color 0s, fill 0s;\n\n  display: flex;\n  flex-direction: column;\n  align-items: left;\n  ", ";\n"])), function (props) {
  if (props.tileType === "hover") {
    return css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n        :hover {\n          cursor: pointer;\n          border-color: ", ";\n        }\n      "])), props.theme.tile.hoverBorder);
  }

  if (props.tileType === "addNew") {
    return css(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n        color: ", ";\n        align-items: center;\n        justify-content: center;\n        :hover {\n          cursor: pointer;\n          color: ", ";\n          background-color: ", ";\n          svg path {\n            fill: ", ";\n          }\n        }\n      "])), props.theme.colors.secondary, props.theme.colors.white, props.theme.colors.secondary, props.theme.colors.white);
  }
});
Wrapper.displayName = "Tile";
export var Header = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  border-bottom: 2px solid ", ";\n"])), function (props) {
  return props.theme.tile.divisionLine;
});
export var Content = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  padding: 1rem 1.25rem;\n"])));
export var Footer = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  margin-top: auto;\n  padding: 0 1rem;\n  margin-bottom: 1rem;\n"])));