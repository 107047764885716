import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4;

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  background-color: ", ";\n  padding: 1.25rem;\n  font-weight: ", ";\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  position: relative;\n  margin-bottom: 2rem;\n  border: 1px solid #d3d3d3;\n  border-radius: 5px;\n"])), function (props) {
  return props.theme.colors.light;
}, function (props) {
  return props.theme.typography.boldFontWeight;
});
export var Overlay = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  padding: 1.25rem;\n  height: auto;\n  /* height: 350px; */\n  overflow: visible;\n  z-index: 10;\n  background-color: white;\n  box-shadow: 0 0 0 9999px rgba(50, 50, 50, 0.1);\n"])));
export var MenuHeader = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  font-weight: ", ";\n  padding-bottom: 2rem;\n"])), function (props) {
  return props.theme.typography.boldFontWeight;
});
export var MenuItem = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: space-between;\n  padding-bottom: 1.5rem;\n  color: ", ";\n\n  svg {\n    transform: rotate(-90deg);\n  }\n"])), function (props) {
  return props.active ? props.theme.colors.activeMenuOption : "";
});