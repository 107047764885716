import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16;

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  border: 1px solid #D3D3D3;\n  border-radius: 5px;\n  background: #F4F5F6;\n  margin-left: 55px;\n  padding: 25px;\n  font-size: 14px;\n  min-height: 520px;\n  min-width: 345px;\n  ", "\n  \n"])), media.smallScreen(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    min-height: none;\n    min-width: none;\n    margin-left: 0px;\n    margin-top: 0px;\n  "]))));
export var Title = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  font-size: 20px;\n  display: flex;\n  justify-content: space-between;\n  margin: 0;\n  text-transform: uppercase;\n  font-weight: ", ";\n  color: #000000;\n  ", "\n  \n"])), function (props) {
  return props.theme.typography.boldFontWeight;
}, media.mediumScreen(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    font-size: ", ";\n    cursor: pointer;\n  "])), function (props) {
  return props.theme.typography.h4FontSize;
}));
export var Content = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  font-size: 14px;\n  \n"])));
export var ProductLine = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  margin-top: 20px;\n  font-size: 14px;\n  \n"])));
export var CartSummaryProductList = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  min-height: 142.5px;\n  font-size: 14px;\n  > * {\n    &:last-child {\n       margin-bottom: 20px;\n    }\n  }\n  \n"])));
export var HR = styled.hr(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  display: block;\n  height: 1px;\n  border: 0;\n  margin: 0;\n  padding: 0;\n  font-size: 14px;\n  \n"])));
export var ArrowUp = styled.div(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  display: none;\n  ", "\n  ", "\n  \n"])), media.mediumScreen(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n    display: unset;\n  "]))), function (props) {
  return props.mobileCartOpened && media.mediumScreen(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n    transform: rotate(180deg);\n  "])));
});
export var CostLine = styled.div(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: space-between;\n  span {\n    display: inline-block;\n  }\n  font-weight: ", ";\n  font-size: 14px;\n  \n  color: #000000;\n"])), function (props) {
  return props.last ? props.theme.typography.boldFontWeight : "normal";
});
export var Costs = styled.div(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  div {\n    margin-bottom: 20px;\n    &:last-of-type {\n      margin-bottom: 0px;\n    }\n  }\n  font-size: 14px;\n  \n  color: #000000;\n"])));
export var CLContainer = styled.div(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n  height: 20px;\n"])));
export var DiscountField = styled.div(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\n  margin-bottom: 20px;\n  ", "\n"])), media.smallScreen(_templateObject16 || (_templateObject16 = _taggedTemplateLiteral(["\n    margin-bottom: 75px;\n  "]))));