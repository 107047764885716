import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8;

import { styled } from "@styles";
export var Dot = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: relative;\n  border-radius: 50%;\n  width: 12px;\n  height: 12px;\n  border: 6px solid ", ";\n"])), function (props) {
  return props.done ? "#53024F" : "#c2c2c2";
});
export var ActiveDot = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  width: 12px;\n  height: 12px;\n  border-radius: 50%;\n  box-shadow: 0 0 0 4px #c2c2c2;\n"])));
export var Label = styled.span(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  white-space: pre;\n  display: block;\n  position: absolute;\n  top: 35px;\n  transform: translateX(-50%);\n  font-size: ", ";\n  letter-spacing: 2%;\n  color: ", ";\n"])), [function (props) {
  return props.theme.typography.smallFontSize;
}], function (props) {
  return props.theme.colors.baseFontColorSemiTransparent;
});
export var LeftLabel = styled(Label)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  transform: none;\n  top: 35px;\n"])));
export var RightLabel = styled(Label)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  transform: none;\n  top: 35px;\n  right: 0;\n"])));
export var ProgressBar = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  z-index: -1;\n  width: 100%;\n  height: 4px;\n  background-color: ", ";\n"])), function (props) {
  return props.done ? "#53024F" : "#c2c2c2";
});
export var Step = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  position: relative;\n  &:not(:last-child) {\n    width: 100%;\n  }\n"])));
export var Wrapper = styled.div(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n  align-items: center;\n  position: relative;\n"])));