import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral([""])));
export var AttributeList = styled.ul(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  columns: 2;\n  column-width: 50%;\n\n  ", ";\n  width: 100%;\n  padding: 0;\n  margin: 0;\n\n  li {\n    margin-bottom: 30px;\n    font-size: ", ";\n  }\n\n  li::before {\n    content: \"\u2022\";\n    margin-right: 20px;\n    color: ", ";\n    color: white;\n  }\n"])), media.largeScreen(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    column-width: 100%;\n    columns: 1;\n  "]))), function (props) {
  return props.theme.typography.h4FontSize;
}, function (props) {
  return props.theme.colors.listBullet;
});
export var Tabs = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-wrap: none;\n  width: 100%;\n  border-bottom: 1px solid ", ";\n  margin-bottom: 70px;\n  overflow: hidden;\n  p {\n    color: white;\n  }\n"])), function (props) {
  return props.theme.colors.tabsBorder;
});
export var TabTitle = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  cursor: pointer;\n  min-width: 230px;\n  font-size: ", ";\n  font-weight: ", ";\n  letter-spacing: 0.02em;\n  color: ", ";\n  color: white;\n  border-bottom-width: 4px;\n  border-bottom-style: solid;\n  border-bottom-color: ", ";\n  padding-bottom: 25px;\n  margin-right: 60px;\n\n  ", ";\n"])), function (props) {
  return props.theme.typography.h3FontSize;
}, function (props) {
  return props.theme.typography.boldFontWeight;
}, function (props) {
  return props.active && props.theme.colors.tabTitle;
}, function (props) {
  return props.active ? props.theme.colors.tabTitle : "transparent";
}, media.smallScreen(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n    font-size: ", ";\n    min-width: 150px;\n    margin-right: 20px;\n  "])), function (props) {
  return props.theme.typography.h4FontSize;
}));
export var AttributeName = styled.span(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  color: ", ";\n  color: white;\n"])), function (props) {
  return props.theme.colors.listAttributeName;
});