import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16, _templateObject17, _templateObject18, _templateObject19, _templateObject20, _templateObject21, _templateObject22, _templateObject23, _templateObject24, _templateObject25, _templateObject26, _templateObject27, _templateObject28, _templateObject29, _templateObject30, _templateObject31;

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  background: #FFFFFF;\n  border: 1px solid #D3D3D3;\n  border-radius: 5px;\n  position: relative;\n  margin-top: 20px;\n  padding: 20px;\n  ", ";\n"])), media.smallScreen(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    flex-direction: column;\n  "]))));
export var Product = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  width: 40%;\n  display: flex;\n  ", ";\n"])), media.smallScreen(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    width: 100%;\n  "]))));
export var Photo = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  width: 17.5%;\n\n  img {\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n  }\n  ", "\n"])), media.smallScreen(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  width: 100%;\n"]))));
export var Description = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  width: calc(82.5% - 40px);\n  margin-left: 40px;\n  padding-right: 20px;\n  ", "\n"])), media.smallScreen(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n    width: 100%;\n  "]))));
export var Sku = styled.p(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  text-align: left;\n  color: black;\n  ", "\n"])), media.smallScreen(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n    text-align: right;\n"]))));
export var Attributes = styled.div(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-auto-columns: max-content;\n  grid-template-columns: repeat(auto-fit, minmax(166px, 500px));\n  color: black;\n"])));
export var SingleAttribute = styled.p(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n  flex-grow: 1;\n  display: flex;\n  justify-content: flex-start;\n  white-space: nowrap;\n  color: black;\n  font-size: 14px;\n  ", "\n"])), media.smallScreen(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n    text-align: right;\n  "]))));
export var Name = styled.p(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n  font-weight: ", ";\n  font-size: 14px;\n  text-align: left;\n  text-transform: uppercase;\n  color: black;\n  width: 200px;\n  ", "\n"])), function (props) {
  return props.theme.typography.boldFontWeight;
}, media.smallScreen(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\n    text-align: right;\n  "]))));
export var Span = styled.span(_templateObject16 || (_templateObject16 = _taggedTemplateLiteral(["\n  ", "\n"])), media.smallScreen(_templateObject17 || (_templateObject17 = _taggedTemplateLiteral(["\n    margin-left: auto;\n  "]))));
export var LightFont = styled.span(_templateObject18 || (_templateObject18 = _taggedTemplateLiteral(["\n  font-size: 14px;\n  color: rgba(125, 125, 125, 0.6);\n  color: black;\n  ", "\n"])), media.smallScreen(_templateObject19 || (_templateObject19 = _taggedTemplateLiteral(["\n    text-align: right;\n  "]))));
export var Price = styled.div(_templateObject20 || (_templateObject20 = _taggedTemplateLiteral(["\n  font-size: 14px;\n  display: flex;\n  font-weight: bold;\n  color: black;\n  width: 10%;\n  display: flex;\n  align-items: center;\n  ", "\n"])), media.smallScreen(_templateObject21 || (_templateObject21 = _taggedTemplateLiteral(["\n    width: 100%;\n  "]))));
export var TotalPrice = styled.div(_templateObject22 || (_templateObject22 = _taggedTemplateLiteral(["\n  font-size: 14px;\n  display: flex;\n  font-weight: bold;\n  color: black;\n  width: 20%;\n  display: flex;\n  align-items: center;\n  ", "\n"])), media.smallScreen(_templateObject23 || (_templateObject23 = _taggedTemplateLiteral(["\n    width: 100%;\n  "]))));
export var PriceLabel = styled.p(_templateObject24 || (_templateObject24 = _taggedTemplateLiteral(["\n  color: black;\n  ", "\n"])), media.smallScreen(_templateObject25 || (_templateObject25 = _taggedTemplateLiteral(["\n    display: block;\n  "]))));
export var Trash = styled.div(_templateObject26 || (_templateObject26 = _taggedTemplateLiteral(["\n  width: 10%;\n  display: flex;\n"])));
export var Remove = styled.div(_templateObject27 || (_templateObject27 = _taggedTemplateLiteral(["\n  font-size: 14px;\n  font-weight: 700;\n  color: #FF0000;\n  cursor: pointer;\n  position: absolute;\n  right: 15px;\n  top: 50%;\n  transform: translate(0, -50%);\n  ", "\n"])), media.smallScreen(_templateObject28 || (_templateObject28 = _taggedTemplateLiteral(["\n    position: relative;\n    right: 0;\n    top: 0;\n    transform: translate(0, 0);\n    margin: 20px 0 0 0;\n  "]))));
export var Quantity = styled.div(_templateObject29 || (_templateObject29 = _taggedTemplateLiteral(["\n  width: 20%;\n  color: black;\n  display: flex;\n  align-items: center;\n  margin: 0 75px;\n  ", "\n"])), media.smallScreen(_templateObject30 || (_templateObject30 = _taggedTemplateLiteral(["\n    width: 100%;\n    padding-right: 0;\n    margin: 20px 0;\n  "]))));
export var QuantityButtons = styled.div(_templateObject31 || (_templateObject31 = _taggedTemplateLiteral(["\n  background: white;\n  display: flex;\n  justify-content: space-between;\n  padding: 0;\n  width: 50px;\n  margin-right: 12.5px;\n\n  svg {\n    cursor: pointer;\n  }\n"])));