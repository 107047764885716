import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9;

import { media, styled } from "@styles";
export var CartContainer = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  min-height: 85vh;\n  max-width: 1400px;\n  margin: 55px auto 0 auto;\n  padding: 0 25px;\n  @media(max-width: 820px) {\n    padding: 45px 25px;\n  }\n"])));
export var Wrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  \n"])));
export var Title = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  font-size: 22px;\n  line-height: 22px;\n  margin-bottom: 20px;\n  color: black;\n"])));
export var CartHeader = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  color: black;\n  ", ";\n"])), media.smallScreen(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n    display: none;\n  "]))));
export var CartFooter = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  color: black;\n"])));
export var Cart = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n\n"])));
export var ProceedButton = styled.div(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  text-align: right;\n  margin: 50px 0;\n  ", ";\n"])), media.smallScreen(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n    text-align: center;\n  "]))));