import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16;

import { styled } from "@styles";
export var AddToCartSelection = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 100%;\n"])));
export var ProductNameHeader = styled.h3(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  font-weight: ", ";\n  margin-bottom: ", ";\n  color: white;\n"])), function (props) {
  return props.theme.typography.boldFontWeight;
}, function (props) {
  return props.theme.spacing.spacer;
});
export var ProductPricing = styled.h4(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  padding-bottom: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-top: 16px;\n  color: white;\n"])), function (props) {
  return props.theme.spacing.spacer;
}, function (props) {
  return props.theme.typography.baseFontSize;
}, function (props) {
  return props.theme.typography.boldFontWeight;
});
export var UndiscountedPrice = styled.span(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  text-decoration: line-through;\n  color: ", ";\n  font-size: ", ";\n"])), function (props) {
  return props.theme.colors.baseFontColorSemiTransparent;
}, function (props) {
  return props.theme.typography.smallFontSize;
});
export var VariantPicker = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  display: grid;\n  margin-top: 10px;\n\n  .react-select-wrapper,\n  .input {\n    width: 50%;\n    margin-bottom: 1rem;\n  }\n"])));
export var QuantityInput = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  margin-top:0px;\n  padding-top: 20px;\n"])));
export var ErrorMessage = styled.p(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  color: ", ";\n  margin-left: auto;\n  font-size: 12px;\n"])), function (props) {
  return props.theme.colors.error;
});
export var Warning = styled.div(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  color: ", ";\n  // width: 75%;\n  font-size: 12px;\n  margin-bottom: 25px;\n"])), function (props) {
  return props.theme.colors.error;
});
export var ProductDescription = styled.div(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  padding-top: 10px;\n  margin-top: 16px;\n  font-size: 12.5px;\n  border-bottom: 1.4px solid #d3d3d3;\n  border-top: 1.4px solid #d3d3d3;\n  padding-bottom: 16px;\n  color: white;\n"])));
export var Features = styled.div(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  padding-top: 6px;\n  margin-top: 20px;\n  font-size: 12px;\n  border-top: 1px solid rgba(208,208,208, 0.4);\n  white-space: pre-line;\n"])));
export var FHeader = styled.div(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  font-weight: ", ";\n"])), function (props) {
  return props.theme.typography.boldFontWeight;
});
export var FeaturesText = styled.div(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n  \n"])));
export var FittingGuideLink = styled.div(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n  text-decoration: underline;\n  font-size: 12px;\n  margin-top: 14px;\n  a {\n    font-weight: bold;\n    color: #323436;\n    opacity: 0.7;\n  }\n"])));
export var QuantityInputHeader = styled.div(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n  font-weight: bold;\n  font-size: 14px;\n  margin-top: 14px;\n  color: white;\n"])));
export var FlexContainer = styled.div(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\n  display: flex;\n"])));
export var FlexColumnContainer = styled.div(_templateObject16 || (_templateObject16 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n"])));