import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;

import { styled } from "@styles";

var borderColors = function borderColors(theme) {
  return {
    action: theme.colors.error,
    error: theme.colors.error,
    neutral: theme.colors.primaryDark,
    success: theme.colors.success
  };
};

export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: ", ";\n  padding: ", ";\n  background-color: ", ";\n  box-shadow: 0px 6px 15px 3px rgba(0, 0, 0, 0.25);\n  position: fixed;\n  bottom: ", ";\n  right: ", ";\n  border-left: 0.4rem solid;\n  border-color: ", ";\n  @media(max-width: 540px) {\n    width: auto;\n    border-left: 0rem solid;\n    border-top: 0.4rem solid;\n  }\n"])), function (props) {
  return props.theme.message.width;
}, function (props) {
  return props.theme.message.padding;
}, function (props) {
  return props.theme.message.backgroundColor;
}, function (props) {
  return props.theme.spacing.spacer;
}, function (props) {
  return props.theme.spacing.spacer;
}, function (props) {
  return borderColors(props.theme)[props.status];
});
export var TopWrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: space-between;\n"])));
export var Title = styled.p(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  letter-spacing: ", ";\n  margin: ", ";\n"])), function (props) {
  return props.theme.message.letterSpacing;
}, function (props) {
  return props.theme.message.titleMargin;
});
export var CloseButton = styled.button(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  cursor: pointer;\n\n  path {\n    transition: 0.3s;\n  }\n\n  &:hover {\n    path {\n      fill: ", ";\n    }\n  }\n"])), function (props) {
  return props.theme.colors.primary;
});
export var Content = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  margin: ", ";\n"])), function (props) {
  return props.theme.message.contentMargin;
});
export var ActionButton = styled.button(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  color: ", ";\n  cursor: pointer;\n  font-size: ", ";\n  text-decoration: underline;\n"])), function (props) {
  return props.theme.colors.secondary;
}, function (props) {
  return props.theme.typography.baseFontSize;
});