/* eslint-disable global-require */
import { generatePageUrl } from "./utils";
export var BASE_URL = "/";
export var PRODUCTS_PER_PAGE = 8;
export var SUPPORT_EMAIL = "support@example.com";
export var PROVIDERS = {
  BRAINTREE: {
    label: "Braintree"
  },
  DUMMY: {
    label: "Dummy"
  },
  AUTHORIZENET: {
    label: "AuthorizeNet",
    displayLabel: "Credit / Debit Card"
  },
  STRIPE: {
    label: "Stripe"
  },
  ADYEN: {
    label: "Adyen",
    script: {
      src: "https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/3.10.1/adyen.js",
      integrity: "sha384-wG2z9zSQo61EIvyXmiFCo+zB3y0ZB4hsrXVcANmpP8HLthjoQJQPBh7tZKJSV8jA",
      crossOrigin: "anonymous"
    },
    style: {
      src: "https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/3.10.1/adyen.css",
      integrity: "sha384-8ofgICZZ/k5cC5N7xegqFZOA73H9RQ7H13439JfAZW8Gj3qjuKL2isaTD3GMIhDE",
      crossOrigin: "anonymous"
    }
  }
};
export var STATIC_PAGES = [{
  label: "About",
  url: generatePageUrl("about")
}];
export var SOCIAL_MEDIA = [{
  ariaLabel: "instagram",
  href: "https://instagram.com/lovefullofdesires?utm_medium=copy_link",
  path: require("../images/instagram-icon.svg")
}];
export var META_DEFAULTS = {
  custom: [],
  description: "Open-source PWA storefront built with Saleor's e-commerce GraphQL API. Written with React and TypeScript.",
  image: "".concat(window.location.origin).concat(require("../images/logo.png")),
  title: "Love Full of Desires",
  type: "website",
  url: window.location.origin
};
export var CheckoutStep;

(function (CheckoutStep) {
  CheckoutStep[CheckoutStep["Address"] = 1] = "Address";
  CheckoutStep[CheckoutStep["Shipping"] = 2] = "Shipping";
  CheckoutStep[CheckoutStep["Payment"] = 3] = "Payment";
  CheckoutStep[CheckoutStep["Review"] = 4] = "Review";
  CheckoutStep[CheckoutStep["PaymentConfirm"] = 5] = "PaymentConfirm";
})(CheckoutStep || (CheckoutStep = {}));

export var CHECKOUT_STEPS = [{
  index: 0,
  link: "/checkout/address",
  name: "Address",
  nextActionName: "Continue to Shipping",
  onlyIfShippingRequired: false,
  step: CheckoutStep.Address
}, {
  index: 1,
  link: "/checkout/shipping",
  name: "Shipping",
  nextActionName: "Continue to Payment",
  onlyIfShippingRequired: true,
  step: CheckoutStep.Shipping
}, {
  index: 2,
  link: "/checkout/payment",
  name: "Payment",
  nextActionName: "Continue to Review",
  onlyIfShippingRequired: false,
  step: CheckoutStep.Payment
}, {
  index: 3,
  link: "/checkout/review",
  name: "Review",
  nextActionName: "Place order",
  onlyIfShippingRequired: false,
  step: CheckoutStep.Review
}, {
  index: 4,
  link: "/checkout/payment-confirm",
  name: "Payment confirm",
  onlyIfShippingRequired: false,
  step: CheckoutStep.PaymentConfirm,
  withoutOwnView: true
}];
export var API_URI_CONF = "";
export var ENABLE_ANALYTICS = false;
export var ENABLE_ECOM_ANALYTICS = false;
export var FB_PIXEL_ID = '246794920373605';
export var FB_OPTIONS = {
  autoConfig: true,
  debug: false
};
export var FB_ADVANCED_OPTIONS = undefined;
export var AUTHORIZENET_LOGIN_ID = "5F67p2zLUe";
export var AUTHORIZENET_CLIENT_KEY = "5ze6eRT3q94t36RRduj69x9Ffg7GudWET7dM4HF6QypPf24SQ328e74qA4UbFx8k";
export var USPS_WEBTOOLS_ID = "624FROGF1057";
export var USPS_WEBTOOLS_ENV = "https://production.shippingapis.com/ShippingAPI.dll";
export var APPLE_PAY_WEBHOOK_URL = "https://dayleenstage.frogfishsolutions.io/plugins/mirumee.applepay/webhook";
export var PAYPAL_EXPRESS_WEBHOOK_URL = "https://dayleenstaage.frogfishsolutions.io/plugins/mirumee.paypalexpress/webhook";
export var MAIL_LIST_WEBHOOK_URL = "https://dayleenstage.frogfishsolutions.io/plugins/mirumee.maillist/webhook";
export var SMARTY_WEBSITE_KEY = "86422230760931141";
export var SMARTY_AUTH_REFERER = "https://localhost:3000";
export var RETURNS_SCREEN_URL = "https://returnsTEMP.frogfishsolutions.com/order";