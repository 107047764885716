import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8;

import { styled } from "@styles";
export var DiscountForm = styled.form(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  \n"])));
export var InputWrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  width: 45%;\n"])));
export var Input = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  \n"])));
export var RowContainer = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: space-between;\n"])));
export var ButtonWrapper = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  width: 50%;\n"])));
export var ChipsWrapper = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  height: 40px;\n  margin-top: 10px;\n"])));
export var MessageWrapper = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  height: 20px;\n  width: 100%;\n  margin-top: 10px;\n"])));
export var Info = styled.span(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  font-size: 12px;\n  color: #ff0000;\n"])));