import { APPLE_PAY_WEBHOOK_URL } from "../config";


export function applePayButtonClicked(
		totalPrice, 
		items, 
		shippingMethods,
		completePaymentCallback
	){
	if(!shippingMethods) { window.location.reload(); return; }

	var label = 'TEMP';
	var sMethods = [];
	for(let i=0;i<shippingMethods.length;i++){
		var s = shippingMethods[i];
		sMethods.push({
			"label": s.name,
    		"detail": " ",
    		"amount": s.price.amount.toString(),
    		"identifier": s.id
		});
	}
	var currentShippingMethod = sMethods[0];
	var originalTot = totalPrice.net.amount.toString();
	var tot = parseFloat(originalTot) + parseFloat(sMethods[0]["amount"]);
	tot = tot.toString();
	const request = {
        "countryCode": "US",
        "currencyCode": "USD",
        "merchantCapabilities": [
            "supports3DS",
			'supportsCredit', 
		  	'supportsDebit'
        ],
        "supportedNetworks": [
            "visa",
            "masterCard",
            "amex",
            "discover"
        ],
        "total": {
            "type": 'final', 
			"label": label, 
			"amount": tot
        },
		"requiredBillingContactFields": ["postalAddress"],
		"requiredShippingContactFields": [
			"postalAddress",
			'phone',
        	'email'
		],
		"shippingMethods": sMethods,
    };
	
	var session = new ApplePaySession(3, request);

	session.onvalidatemerchant = function (event) {
		var promise = performValidation(event.validationURL);
		promise.then(function (merchantSession) {
			session.completeMerchantValidation(merchantSession);
		}); 
	}

	function performValidation(valURL) {
		return new Promise(function(resolve, reject) {
			var xhr = new XMLHttpRequest();
			xhr.onload = function() {
				var data = JSON.parse(this.responseText);
				resolve(data);
			};
			xhr.onerror = reject;
			xhr.open('POST', APPLE_PAY_WEBHOOK_URL, true);
			xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
			xhr.send('action=MERCHANTVERIFY&validationUrl='+valURL);
		});
	}

	session.onpaymentmethodselected = function(event) {
		var newTotal = { 
			type: 'final', 
			label: label, 
			amount: tot
		};
		var newLineItems = [
			newTotal
		];
		session.completePaymentMethodSelection(newTotal, newLineItems);
	}

	session.onpaymentauthorized = function (event) {
		var promise = sendPaymentToken(event.payment.token.paymentData, event);
		promise.then(function (data) {	
			var status;
			if (data){
				status = ApplePaySession.STATUS_SUCCESS;
			} else {
				status = ApplePaySession.STATUS_FAILURE;
			}		
			session.completePayment(status);
			completePaymentCallback(event, data, currentShippingMethod);
		}); 
	}

	function sendPaymentToken(paymentToken, event) {
		return new Promise(function(resolve, reject) {
			var xhr = new XMLHttpRequest();
			xhr.onload = function() {
				var data = JSON.parse(this.responseText);
				resolve(data);
			};
			xhr.onerror = reject;
			xhr.open('POST', APPLE_PAY_WEBHOOK_URL, true);
			xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
			var encodedTokenObj = window.btoa(JSON.stringify(paymentToken));

			var a = event.payment.billingContact;
			var sc = event.payment.shippingContact;

			var params = 'action=COMPLETEPAYMENT'
							+ '&token=' 
							+ encodedTokenObj
							+ '&amount='
							+ tot
							+ '&firstName='
							+ a.givenName
							+ '&lastName='
							+ a.familyName
							+ '&email='
							+ sc.emailAddress
							+ '&address='
							+ a.addressLines[0]
							+ '&city='
							+ a.locality
							+ '&zip='
							+ a.postalCode
							+ '&state='
							+ a.administrativeArea;
			xhr.send(params);
		});
	}

	session.onshippingmethodselected = function(event) {
		currentShippingMethod = event.shippingMethod;
		var shippingAmount = parseFloat(event.shippingMethod.amount);
		var totalWithShipping = shippingAmount + parseFloat(originalTot);
		var newTotal = { 
			type: 'final', 
			label: label, 
			amount: totalWithShipping.toString()
		};
		var newLineItems = [
			newTotal
		];
		tot = totalWithShipping.toString();
		session.completeShippingMethodSelection(
			ApplePaySession.STATUS_SUCCESS,
			newTotal,
			newLineItems,
		);
	}

	session.onshippingcontactselected = function(event) {
		currentShippingMethod = sMethods[0];
		var shippingAmount = parseFloat(sMethods[0].amount);
		var totalWithShipping = shippingAmount + parseFloat(originalTot);
		var newTotal = { 
			type: 'final', 
			label: label, 
			amount: totalWithShipping.toString()
		};
		var newLineItems = [
			newTotal
		];
		tot = totalWithShipping.toString();

		session.completeShippingContactSelection(
			ApplePaySession.STATUS_SUCCESS,
			sMethods,
			newTotal,
			newLineItems
		);
	}

	
	session.oncancel = function(event) {
		session.abort();
	}
	
	session.begin();
}
