import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16, _templateObject17, _templateObject18, _templateObject19, _templateObject20;

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral([""])));
export var Divider = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  width: 100%;\n  border-bottom: 1px solid\n    ", ";\n  margin: 18px 0;\n  margin-top: 4px;\n  width: 100%;\n"])), function (props) {
  return props.theme.colors.baseFontColorTransparent;
});
export var Title = styled.h3(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  font-weight: ", ";\n  color: black;\n  padding: 0 0 1rem 0;\n  font-size: 16px;\n"])), function (props) {
  return props.theme.typography.boldFontWeight;
});
export var CardLogoContainer = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  background: #FAFAFA;  \n  border: 1px solid #D3D3D3;\n  border-radius: 5px;\n  margin-bottom: 10px;\n  ", "\n"])), media.smallScreen(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n      width: 100%;\n      justify-content: center;\n      margin-left: 0;\n  "]))));
export var CardContainer = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  height: auto;\n  width: 100%;\n  max-width: 550px;\n  margin: auto;\n  background: transparent;\n"])));
export var ApplePayButton = styled.button(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  display: inline-block;\n  -webkit-appearance: -apple-pay-button;\n  -apple-pay-button-style: black;\n  margin-bottom: 14px;\n  width:64%;\n  height: 38px;\n"])));
export var ApplePay = styled.div(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  width: 100%;\n  text-align: center;\n"])));
export var Or = styled.div(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  margin-bottom: 15px;\n  font-size: 18px;\n"])));
export var ContactFields = styled.div(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  width: 100%;\n  ", "\n"])), media.smallScreen(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n      width: 100%;\n  "]))));
export var EmailSignupCheck = styled.div(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n  width: 100%;\n  margin-top: -25px;\n"])));
export var EmailSignupText = styled.p(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n  color: #666666;\n  margin-left: -10px;\n  margin-top: -2.5px;\n  font-size: 16px;\n"])));
export var AltPaymentOptionsContainer = styled.div(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n  height: 100px;\n  width: auto;\n  display: flex;\n  justify-content: left;\n  margin-left: -20px;\n  background: transparent;\n  width: 100%;\n  justify-content: space-between;\n  padding-left: 20px;\n  padding-right: 20px;\n  ", "\n"])), media.smallScreen(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\n      width: 98%;\n      justify-content: center;\n      margin-left: 0;\n  "]))));
export var AltPaymentOptionContainer = styled.div(_templateObject16 || (_templateObject16 = _taggedTemplateLiteral(["\n  padding: 0px;\n  display: flex;\n  justify-content: center;\n  padding-top: 6%;\n"])));
export var OrContainer = styled.div(_templateObject17 || (_templateObject17 = _taggedTemplateLiteral(["\n  display: flex;\n  width: 100%;\n  height: 30px;\n  justify-content: space-between;\n  ", "\n"])), media.smallScreen(_templateObject18 || (_templateObject18 = _taggedTemplateLiteral(["\n      width: 98%;\n  "]))));
export var TextDivider = styled.div(_templateObject19 || (_templateObject19 = _taggedTemplateLiteral(["\n  width: 45%;\n  margin: 18px 0;\n  margin-top: 4px;\n  ", "\n"])), media.smallScreen(_templateObject20 || (_templateObject20 = _taggedTemplateLiteral(["\n\n  "]))));