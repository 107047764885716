import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10;

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  color: black;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n  margin-top: 20px;\n"])));
export var Row = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  margin-bottom: 15px;\n  margin-left: auto;\n  font-size: 14px;\n"])));
export var SubtotalText = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  color: black;\n"])));
export var SubtotalPrice = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  color: black;\n"])));
export var ShippingText = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  color: black;\n"])));
export var ShippingPrice = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  color: black;\n"])));
export var DiscountText = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  color: black\n"])));
export var DiscountPrice = styled.div(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  color: black;\n"])));
export var TotalText = styled.div(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  font-weight: bold;\n  color: black;\n"])));
export var TotalPrice = styled.div(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  \n  color: black\n"])));