import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4;

import { media, styled } from "@styles";
var padding = {
  md: "0.9rem 3.7rem",
  sm: "0.1rem 2rem"
};
export var Primary = styled.button(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  background-color: #53024F;\n  padding: ", ";\n  border: none;\n  border-radius: 6px;\n  transition: 0.0s;\n  outline: none;\n  height: 46px;\n  cursor: pointer;\n  color: ", ";\n  width: ", "\n\n  &:hover {\n    background-color: #63015e;\n    color: ", ";\n  }\n\n  &:active {\n    background-color: #63015e;\n  }\n\n  &:disabled {\n    background-color: #d3d3d3;\n\n    &,\n    &:hover {\n      cursor: default;\n    }\n  }\n\n  ", "\n"])), function (props) {
  return padding[props.size];
}, function (props) {
  return props.theme.button.colors[props.color].color;
}, function (props) {
  return props.fullWidth ? "100%" : "auto";
}, function (props) {
  return props.theme.button.colors[props.color].hoverColor;
}, media.smallScreen(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    padding:  0.65rem 1rem;\n    width: ", ";\n  "])), function (props) {
  return props.fullWidth ? "100%" : "88%";
}));
export var Secondary = styled(Primary)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  box-shadow: inset 0px 0px 0px 3px\n    ", ";\n  border-left: 1px solid ", ";\n  border-right: 1px solid ", ";\n"])), function (props) {
  return props.theme.button.colors.secondary.color;
}, function (props) {
  return props.theme.button.colors.secondary.color;
}, function (props) {
  return props.theme.button.colors.secondary.color;
});
export var Text = styled.span(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: inline-block;\n  font-size: 15px;\n  line-height: 0px;\n"])));