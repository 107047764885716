import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10;

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral([""])));
export var Row = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  width: 100%;\n  flex-direction: row;\n  text-align: center;\n  justify-content: center;\n  align-items: center;\n  height: 5rem;\n  cursor: pointer;\n\n  border-bottom: 1px solid ", ";\n"])), function (props) {
  return props.theme.colors.tableDivider;
});
export var HeaderRow = styled(Row)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  color: ", ";\n  cursor: default;\n"])), function (props) {
  return props.theme.colors.lightFont;
});
export var IndexNumber = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  width: 15%;\n  color: black;\n  ", "\n"])), media.smallScreen(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n     width: 50%;\n  "]))));
export var ProductsOrdered = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  width: 25%;\n  color: black;\n  display: flex;\n  flex-wrap: nowrap;\n  justify-content: center;\n\n  img {\n    max-width: 50px;\n    height: auto;\n  }\n"])));
export var DateOfOrder = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  width: 25%;\n  color: black;\n"])));
export var Value = styled.div(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  width: 10%;\n  color: black;\n"])));
export var Status = styled.div(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  width: 25%;\n  color: black;\n  ", "\n"])), media.smallScreen(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n     width: 50%;\n  "]))));